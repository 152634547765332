.page-intro-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    object-fit: contain;
    position: relative;
    clear: both;
  }

  .page-intro-container.home {
    background: url('/public/images/front1.JPG') center center/cover no-repeat;
  }
  
  .page-intro-container.careers {
    background: url('/public/images/car1.jpg') center center/cover no-repeat;
  }
  
  .page-intro-container.contact {
    background: url('/public/images/car_rear.jpeg') center center/cover no-repeat;
  }

  .page-intro-container.faq {
    background: url('/public/images/tacoma1.jpeg') center center/cover no-repeat;
  }
  .page-intro-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
    text-align: center;
  }
  
  .page-intro-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Questrial', sans-serif;
    text-align: center;
  }

  /* img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  } */

  @media screen and (max-width: 960px) {
    .page-intro-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .page-intro-container > p {
      font-size: 30px;
    }
}