.contact-form-section {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #010606;
    color: white;
    height: auto;
    text-align: center;
    padding: 50px 0px 30px 0px;
  }