.home-title-desc {
    font-family: 'Questrial', sans-serif;
}
.home-title-button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
.services-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #010606;
    position: relative;
    clear: both;
    padding-top: 50px;
}
.services-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 30px;
    padding: 0 50px;
}
.services-title {
    font-size: 3.5rem;
    color: white;
    margin-bottom: 50px;
    text-align: center;
}
.services-card {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    min-height: 400px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2 ease-in-out;
}
.services-card.middle {
    min-height: 420px;
}
.services-card-title {
    font-size: 2.2rem;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}
.services-card-description {
    font-size: 1.2rem;
    text-align: center;
    font-family: 'Questrial', sans-serif;
}
.map-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content:baseline;
    align-items: center;
    background-color: #010606;
    padding-bottom: 30px;
    position: relative;
}
.map-title {
    font-size: 3.5rem;
    color: white;
    margin: 34px 34px;
    text-align: center;
}
.map-card {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2 ease-in-out;
}
.map-card-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 30px;
    margin: 0 auto;
}
.map-card-title {
    font-size: 2.2rem;
    margin-bottom: 10px;
    margin-top: 10px;
}
.map-box {
    width: 600px;
    height: 600px;
}
.map-box-content {
    width: auto;
    height: auto;
}
.map-card-description {
    font-size: 1.2rem;
    text-align: center;
    font-family: 'Questrial', sans-serif;
}
.map-wrapper {
    max-width: 1500px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 0 50px;
}
@media screen and (max-width: 1200px) {
    .services-container {
        height: auto;
        clear: both;
        padding: 54px 0 0px;
    }
    .services-wrapper {
        grid-template-columns: 1fr;
        padding: 0 20px;
        width: 100%;
    }
    .services-title {
        font-size: 2rem;
    }
    .services-card, .services-card.middle {
        min-height: 0px;
        height: auto;
    }
    .services-card-title {
        
    }
    .services-card-description {
        font-size: 1.5rem;
    }
    .map-title {
        font-size: 1.9rem;
    }
    .map-box {
        width: 100%;
        min-height: 300px;
    }
    .map-wrapper {
        padding: 0 20px;
        width: 100%;
        max-width: 960px;
    }
    .map-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
}

@media screen and (max-width: 450px) {
    .services-title {
        margin-bottom: 24px;
    }
    .services-card {
        padding: 10px;   
    }
    .services-card-description {
        font-size: 1.2rem;
    }
    .map-card-description {
        font-size: 1.0rem;
    }
    .map-box {
        width: 100%;
        min-height: 300px;
    }
    .map-card-content-wrapper {
        padding: 0 0px;
    }
    .map-card {
        padding: 10px;
        margin: auto;
    }
}